exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-golf-clubs-js": () => import("./../../../src/pages/golf-clubs.js" /* webpackChunkName: "component---src-pages-golf-clubs-js" */),
  "component---src-pages-golf-courses-js": () => import("./../../../src/pages/golf-courses.js" /* webpackChunkName: "component---src-pages-golf-courses-js" */),
  "component---src-pages-golf-equipment-js": () => import("./../../../src/pages/golf-equipment.js" /* webpackChunkName: "component---src-pages-golf-equipment-js" */),
  "component---src-pages-golf-news-js": () => import("./../../../src/pages/golf-news.js" /* webpackChunkName: "component---src-pages-golf-news-js" */),
  "component---src-pages-golf-training-js": () => import("./../../../src/pages/golf-training.js" /* webpackChunkName: "component---src-pages-golf-training-js" */),
  "component---src-pages-golfers-js": () => import("./../../../src/pages/golfers.js" /* webpackChunkName: "component---src-pages-golfers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

